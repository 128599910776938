<div class="modal-header">
    <h2 mat-dialog-title>
        <span *ngIf="!data.playlist" trans>New Playlist</span>
        <span *ngIf="data.playlist" trans>Update Playlist</span>
    </h2>
    <button type="button" (click)="close()" class="close-button" mat-icon-button tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form (ngSubmit)="confirm()" [formGroup]="form" ngNativeValidate>
   <mat-dialog-content>
       <div class="flex-container">
           <upload-image-control [uploadConfig]="uploadImgConfig" [defaultBackground]="defaultImage" formControlName="image"></upload-image-control>

           <div class="many-inputs">
               <div class="input-container name-input">
                   <label for="playlist-name" trans>Name</label>
                   <input type="text" formControlName="name" id="playlist-name" required min="1" max="250">
                   <div class="error" *ngIf="errors.name">{{errors.name}}</div>
               </div>

               <div class="setting-toggle-container">
                   <mat-slide-toggle id="playlist-collaborative" formControlName="collaborative" trans>Collaborative</mat-slide-toggle>
                   <p trans>Invite other users to add tracks.</p>
               </div>

               <div class="setting-toggle-container">
                   <mat-slide-toggle id="playlist-visibility" formControlName="public" trans>Public</mat-slide-toggle>
                   <p trans>Everyone can see public playlists.</p>
               </div>
           </div>
       </div>
       <div class="input-container description-input">
           <label for="playlist-description" trans>Description</label>
           <textarea formControlName="description" id="playlist-description" rows="4" placeholder="Give your playlist a catchy description." trans-placeholder></textarea>
           <div class="error" *ngIf="errors.description">{{errors.description}}</div>
       </div>
   </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" class="cancel-button" mat-button trans>Close</button>
        <button type="submit" class="submit-button" mat-raised-button color="accent" [disabled]="loading">
            <span *ngIf="!data.playlist" trans>Create</span>
            <span *ngIf="data.playlist" trans>Update</span>
        </button>
    </mat-dialog-actions>
</form>
