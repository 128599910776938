import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { BackendResponse } from '@common/core/types/backend-response';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Planner, PlannerPlay } from 'src/app/models/Planner';
import { Track } from 'src/app/models/Track';

export interface GetPlannerResponse {
    planner: PlannerPlay[];
    s:string;
}
export interface GetPlannerAdsResponse {
    ads: PlannerPlay;
    date:string;
}

@Injectable({
    providedIn: 'root'
})
export class PlannerService {

    static BASE_URI = "planner";
    private plannerIdx: number = 90000;
    private plans: PlannerPlay[] = [];
    public PlannersDeleted$ = new Subject<number>();
    private isInit: boolean = false;
    private intervalTimer: any;
    private isLoading: boolean = false;
    private currenTime:Date;
    private startAdsInitTime:string;
    private selectedTrack?:PlannerPlay;
    private currentAds:Track;
    private nextAds:Track;
    private lastAds: Track;
    private isLoadingNextAds :boolean = false;


  

    //  getNext():Track {
    //     // await this.getLoadNextAds();
    //     const plan = this.calculateNextAds();
    //     if (!plan || ! plan.url) return null;
    //     this.refreshCurrenTime();
    //     const id = plan.id || this.plannerIdx++;
    //     plan.id = id;
    //     return {
    //         genres: [],
    //         id,
    //         model_type: "Planner",
    //         name: plan.time + " " + plan.title,
    //         tags: [],
    //         duration: plan.duration,
    //         p_track_id: id,
    //         url: plan.url,
    //         artists:[
    //         ]

    //     }
    // }

    getCurrentQueen(tracksList:Track[]):Track[]{
        if(this.nextAds?.url){
        tracksList.unshift(this.nextAds);
        }
        return tracksList;
    }

    getCurrent() : Track{
        if(this.currentAds && this.currentAds.url ==  this.nextAds.url) {
            return this.currentAds;
        }
        if(this.nextAds?.url){
             const d = new Date();
             const planDate = new Date(this.nextAds.updated_at);
             d.setMinutes(d.getMinutes() + 1);
             
             if(d.getTime()>= planDate.getTime() ||!(1+ planDate.getTime() > 0)){
                 this.currentAds = this.nextAds;
                console.log("time in",this.nextAds);
             }else{
                console.log("time out",this.nextAds);
             }
        }else{
           return null;
        }
        return this.currentAds;
    }
    async setHasPlaying(track: Track) {
        // const i = this.plans.findIndex(e => e.id == track.id);
        // if (i > -1) {
        //     this.plans[i].hasPlayed = true;
        //     this.plans.splice(i, 1);
        //     this.selectedTrack = null;
        // }
        if(track.plays == 1 || track.plays == -2){
            const id = this.currentAds?.id;    
            this.lastAds = {...this.nextAds};
            
            this.currentAds = null;
            this.nextAds = null;
            if(id){
                this.http.post(`${PlannerService.BASE_URI}/hasPlayed/`+ id,{
                    id,
                }).toPromise();
            }
        }
        if(track?.plays!=-2){
            track.plays = (track.plays ||  0  ) -1;
            if(track.plays<-2)track.plays = -2;
        }
    }

    // async setHasPlaying(track: Track) {
    //     if(this.currentAds?.id){

    //         await  this.http.post(`${PlannerService.BASE_URI}/hasPlayed/`+ this.currentAds.id,{
    //             id:this.currentAds.id,
    //         }).toPromise();
    //     }
    //     this.currentAds = null;
    //     // const i = this.plans.findIndex(e => e.id == track.id);
    //     // if (i > -1) {
    //     //     this.plans[i].hasPlayed = true;
    //     //     this.plans.splice(i, 1);
    //     //     this.selectedTrack = null;
    //     // }

    // }

    constructor(private http: AppHttpClient) {
        // this.init();
    }


    // async init() {
    //     if (!this.isInit) {
    //         this.isInit = true;
    //         this.intervalTimer = setInterval(async () => {
    //             if (!this.isLoading && !this.calculateNextAds()) {
    //                 await this.loadAds();
    //             }
    //         }, 1000 * 60 * 3);
    //     }
    // }

    // public loadAds() {
    //     if (!this.isLoading) {
    //         this.isLoading = true;
            
    //         return this.getFromUser(this.startAdsInitTime).pipe(
    //             switchMap((e: GetPlannerResponse) => {
    //                 if(!this.currenTime){
    //                     this.refreshCurrenTime();
    //                 }
    //                 this.plans = this.calculatePlannerTimeSpan(e.planner).filter(e=> e.date.getTime() >= this.currenTime.getTime());
    //                 if(this.plans.length>0){
    //                     this.startAdsInitTime = e.s;
    //                 }
    //                 return of(this.plans);
    //             })
    //         ).toPromise().then((e: any) => this.isLoading = false).catch((e: any) => this.isLoading = false);
    //     }
    // }

    async getLoadNextAds(duration?:number){
        if(this.isLoadingNextAds || this.nextAds?.url) return;
        this.isLoadingNextAds = true;
        await this.http.post(`${PlannerService.BASE_URI}/nextads`,{duration:duration}).pipe(
            catchError((e:any)=>{ this.isLoadingNextAds = false; return of(false)}),
            switchMap((e: GetPlannerAdsResponse) => {
                if(!(this.lastAds?.id && e.ads?.id && this.lastAds.id == e.ads?.id && e.ads.time == this.lastAds.description )){
                    this.nextAds = e?.ads? {
                        genres: [],
                        description:e.ads.time,
                        id:e.ads.id,
                        model_type: "Planner",
                        name: e.ads.time + " " + e.ads.title,
                        tags: [],
                        duration: e.ads.duration,
                        p_track_id: this.plannerIdx++,
                        url: e.ads.url,
                        artists:[
                        ],
                        updated_at:e.ads.date.toString()

                    }: null;
                }

                this.isLoadingNextAds = false;
                return of(this.nextAds);
            }),
            catchError((e:any)=>{ this.isLoadingNextAds = false; return of(false)}),
        ).toPromise();
    }

    public getAll(): BackendResponse<GetPlannerResponse> {
        return this.http.get(`${PlannerService.BASE_URI}`);
    }
    public getFromUser(s?:string): BackendResponse<GetPlannerResponse> {
        return this.http.post(`${PlannerService.BASE_URI}/play`,{s});
    }

    public get(id: number, params: object = {}): BackendResponse<GetPlannerResponse> {
        return this.http.get(`${PlannerService.BASE_URI}/${id}`, params);
    }

    
    public getUserTracks(user_id: number): BackendResponse<GetPlannerResponse> {
        return this.http.post(`${PlannerService.BASE_URI}/track/user`, {user_id});
    }
    public getUserPlan(user_id: number): BackendResponse<GetPlannerResponse> {
        return this.http.post(`${PlannerService.BASE_URI}/user`, {user_id});
    }

    public create(payload: Partial<Planner>): BackendResponse<{ Planner: Planner }> {
        return this.http.post(PlannerService.BASE_URI, payload);
    }

    public createTrack(payload: Partial<Planner>): BackendResponse<{ Planner: Planner }> {
        return this.http.post(`${PlannerService.BASE_URI}/track`, payload);
    }
    public updateTrack(id: number, payload: Partial<Planner>): BackendResponse<{ Planner: Planner }> {
        return this.http.put(`${PlannerService.BASE_URI}/track/${id}`, payload);
    }
    
    public update(id: number, payload: Partial<Planner>): BackendResponse<{ Planner: Planner }> {
        return this.http.put(`${PlannerService.BASE_URI}/${id}`, payload);
    }

    public delete(id: number) {
        return this.http.delete(PlannerService.BASE_URI, { id }).pipe(tap(() => {
            this.PlannersDeleted$.next(id);
        }));
    }

    public deleteTrack(id: number) {
        return this.http.delete(`${PlannerService.BASE_URI}/track`, { id });
    }
    
    public logPlay(Planner: Planner, params: { queueId?: string }) {
        return this.http.post(`Planner/plays/${Planner.id}/log`, params);
    }


    calculatePlannerTimeSpan(planner: PlannerPlay[]) {
        for (let index = 0; index < planner.length; index++) {
            const element = planner[index];
            const tm = element.time.split(":");
            const d = new Date();
            // if (d.getHours().toString() > tm[0]) {
            //     d.setDate(d.getDate() + 1);
            // }
            d.setHours(parseInt(tm[0]));
            d.setMinutes(parseInt(tm[1]));
            element.date = d;
        }
        return planner;
    }

    // calculateNextAds(): PlannerPlay {
    //     if( this.selectedTrack ) return this.selectedTrack;
    //     if (!this.plans || this.plans.length < 1) this.loadAds();
    //     if(!this.currenTime) {
    //         this.refreshCurrenTime();
    //     }
    //     const d = new Date();
    //     d.setMinutes(d.getMinutes() + 1);
    //     this.selectedTrack =  this.plans.find(e => e.date?.getTime() >= this.currenTime?.getTime()  &&  e.date?.getTime() <= d?.getTime()  && !e.hasPlayed);
    //     return this.selectedTrack;
    // }

    refreshCurrenTime(){
        this.currenTime =  new Date();
        this.currenTime.setMinutes(this.currenTime.getMinutes() - 1);
    }
}
