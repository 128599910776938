<div class="plan-required">
  <img
    class="album-border-image"
    [src]="settings.getAssetUrl('images/audiorooms-logo.png')"
    alt="album border image"
  />

  <h2 class="content-title" trans>
    Um die Musikwiedergabe zu ermöglichen, müssen Sie ein Abonnement auswählen.
  </h2>
  <div class="buttons">
    <a
      mat-dialog-close
      href="https://crm.audiorooms.de/"
      target="_blank"
      mat-raised-button
      color="accent"
      trans
      >Plan wählen</a
    >
  </div>
</div>
