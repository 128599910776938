<ng-container *ngIf="datatable.paginator.paginatedOnce$ | async">
    <header>
        <h1> <span trans>Radio</span></h1>
  
    </header>

    <ad-host slot="ads.general_top" class="margin-bottom"></ad-host>

    <media-grid>
        <div class="media-grid-item" *ngFor="let radio of datatable.data$ | async; trackBy: trackByFn">
        <div class="img-container" >
            <img [alt]="radio.name" draggable="false" [src]="radio.image ? radio.image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'">

            <playback-control-button (play)="play(radio)" (pause)="pause()" [playing]="playing(radio)"></playback-control-button>
        </div>
        <div class="footer">
            <a class="radio-name primary-text">{{radio.name}}</a>
            <div class="creator-name secondary-text">
            </div>
        </div>
    </div>
        
    </media-grid>
</ng-container>

<loading-indicator [isVisible]="datatable.paginator.loading$ | async" class="inline"></loading-indicator>

<div class="no-results-message" *ngIf="datatable.paginator.noResults$ | async">
    <mat-icon class="icon" svgIcon="album"></mat-icon>
    <div class="text">
        <div class="main" trans>Nothing to display.</div>
        <div class="secondary" trans>You have not added any playlists to your library yet.</div>
    </div>
</div>
