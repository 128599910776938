<div class="header">
    <media-image [media]="data.item"></media-image>
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.artist(data.item)">{{
            data.item.name
        }}</a>
    </div>
</div>

<div class="context-menu-panel primary-panel">
    <div class="panel-body">
        <div class="context-menu-item" *ngIf="inLibrary()" (click)="removeFromLibrary()" trans>
            Unfollow
        </div>
        <div class="context-menu-item" *ngIf="!inLibrary()" (click)="saveToLibrary()" trans>
            Follow
        </div>
        <div
            class="context-menu-item"
            (click)="goToArtistRadio()"
            *ngIf="
                !settings.get('player.hide_radio_button') &&
                settings.get('artist_provider') === 'spotify'
            "
            trans
        >
            Go to Artist Radio
        </div>
        <div class="context-menu-item" (click)="copyLinkToClipboard()" trans>Copy Artist Link</div>
        <div class="context-menu-item" (click)="openShareModal()" trans>Share</div>
        <a
            class="context-menu-item"
            [routerLink]="urls.editArtist(data.item.id)"
            *ngIf="canEditArtist"
            trans
            >Edit</a
        >
    </div>
</div>
