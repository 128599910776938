import { DuplicateConfirmModalData } from '@common/core/ui/duplicate-playlist-confirm-modal/duplicate-playlist-confirm-modal.component';
import {ConfirmModalData} from '../core/ui/confirm-modal/confirm-modal.component';

export const DELETE_RESOURCE_MESSAGE: ConfirmModalData = {
    title: `Delete :resource`,
    body:  `Are you sure you want to delete selected :resource?`,
    ok:    'Delete'
};

export const DUPLICATE_RESOURCE_MESSAGE: DuplicateConfirmModalData = {
    title: `Duplicate :resource`,
    body:  `Are you sure you want to duplicate selected :resource?`,
    ok:    'Yes'
};
