<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{data.title}}</h2>
    <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>


 
<!-- 
 <mat-form-field>
    <mat-label>Select user</mat-label>
    <mat-select [(value)]="user">
      <mat-option [value]="u.id" trans *ngFor="let u of users$ | async">{{ u.display_name }} | {{ u.email}}
      </mat-option>
    </mat-select>
  </mat-form-field> -->
 
<div class="mat-dialog-contents" mat-dialog-content>
    <p>{{data.body}}</p>
    <p class="bold" *ngIf="data.bodyBold">{{data.bodyBold}}</p>

    <loading-indicator [isVisible]="loading$ | async" class="small"></loading-indicator>

     <mat-form-field appearance="fill">
        <mat-label trans>Select User</mat-label>
        <mat-select   [(ngModel)]="user">
          <mat-option [value]="u.id" *ngFor="let u of users$ | async">{{ u.display_name }} | {{ u.email}}</mat-option>
        </mat-select>
      </mat-form-field>

  
</div>

<div class="buttons right mat-dialog-actions" mat-dialog-actions>
    <button (click)="close()" type="button" mat-button class="button cancel cancel-button">{{data.cancel}}</button>
    <button type="submit"  [disabled]="loading$ | async" mat-raised-button color="accent" class="button primary submit-button" cdkFocusInitial (click)="confirm()">{{data.ok}}</button>
</div>
