<div class="modal-header">
    <div class="dialog-title-image">
        <img class="album-border-image" [src]="settings.getAssetUrl('images/audiorooms-logo.png')" alt="album border image">

    </div>
    <button
        type="button"
        mat-dialog-close
        class="close-button"
        mat-icon-button
        tabindex="-1"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content >
    <ng-container>
        <ng-container>
            <div class="content">
                <h2 class="content-title" trans>Um die Musikwiedergabe zu ermöglichen, müssen Sie ein Abonnement auswählen.</h2>
                <div class="buttons">
                    <a mat-dialog-close    href="https://crm.audiorooms.de/" target="_blank" mat-raised-button color="accent" trans>Plan wählen</a>
                </div>
                <!-- <div class="text"><span trans>Noch keinen Account? </span> <a href="https://audiorooms.de/registrierung/" class="primary-color" target="_blank" trans>Registrierung</a></div> -->
            </div>
        </ng-container>

    </ng-container>
</mat-dialog-content>

