import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { User } from '@common/core/types/models/User';
import { Users } from '@common/auth/users.service';
import { Toast } from '@common/core/ui/toast.service';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { UploadApiConfig } from '@common/uploads/types/upload-api-config';
import { AvatarValidator } from '@common/account-settings/avatar-validator';
import { UploadUri } from '@common/uploads/types/upload-uri.enum';
import { randomString } from '@common/core/utils/random-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { AudioUploadValidator } from 'src/app/web-player/audio-upload-validator';
import { openUploadWindow } from '@common/uploads/utils/open-upload-window';
import { UploadInputConfig, UploadInputTypes } from '@common/uploads/upload-input-config';
import { UploadQueueService } from '@common/uploads/upload-queue/upload-queue.service';
import { WaveformGenerator } from 'src/app/web-player/tracks/waveform/waveform-generator';
import { DefaultImagePaths } from 'src/app/web-player/default-image-paths.enum';
import { PlannerService } from 'src/app/web-player/tracks/planner.service';
import { Planner, PlannerTrack } from 'src/app/models/Planner';
import { schulduler_typeList } from '../schulduler-type-list';
import { TrackUploadResponse } from 'src/app/uploading/track-form/track-form.component';
import { UploadedFile } from '@common/uploads/uploaded-file';

export interface CrupUserPlanTrackModalData {
    track?: PlannerTrack;
    state: "",
}

@Component({
    selector: 'crup-user-plan-track-modal',
    templateUrl: './crup-user-plan-track-modal.html',
    styleUrls: ['./crup-user-plan-track-modal.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class CrupUserPlanTrackModalComponent implements OnInit {
    public loading$ = new BehaviorSubject<boolean>(false);
    @ViewChild('audioPlayerRef') protected audioPlayerRef: ElementRef;
    clickButton: ElementRef<HTMLButtonElement>;

    playAudio: string;
    // users$ = new BehaviorSubject<User[]>([]);
    public user: String;
    // public overrideData$= new BehaviorSubject<PlannerTrack>({url:null,user_id:null,name:null,type:null,duration:0,id:-1});
    public form = this.fb.group({
        // title: [''],
        user_id: [''],
        type: [''],
        // name:[]
    });
    public errors$ = new BehaviorSubject<Partial<Planner>>({});


    public schulduler_typeList = [];
    public typeList: string[] = ["Audiologo", "Advertisement", "Announcement"];

    public timesHour = [];
    //  ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"];
    // usersUnSubcribe: Subscription;
    uploadConfig: UploadInputConfig = {
        types: [UploadInputTypes.audio],
        multiple: true,
    };

    public defaultImage$ = new BehaviorSubject<string>(DefaultImagePaths.track);
    public trackList$ = new BehaviorSubject<{
        type: any;
        duration: any; id: string, file: UploadedFile, process: boolean
    }[]>([]);

    constructor(
        private dialogRef: MatDialogRef<CrupUserPlanTrackModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CrupUserPlanTrackModalData,
        private plannerSerive: PlannerService,
        private toast: Toast,
        private fb: FormBuilder,
        private avatarValidator: AvatarValidator,
        private audioValidator: AudioUploadValidator,
        private uploadQueue: UploadQueueService,
        private waveGenerator: WaveformGenerator,
    ) { }

    public get getUser() {
        return this.form.controls['user_id'].value;
    }

    getDate(v: any, s: number, e: number) {
        try {
            const d = new Date(v);
            d.setUTCHours(d.getHours());
            return d.toISOString().substring(s, e);
        } catch (error) {
            return null
        }

    }

    ngOnInit() {
        if (this.data.track) {
            this.form.patchValue({
                ...this.data.track,
            });
            // this.overrideData$.next({...this.data.track});
        }
        this.uploadConfig.multiple = !this.data.track.id;
        if (this.data.track.id && this.data.track.name) {
            const f: { duration: any; id: string, file: UploadedFile, process: boolean ,type:string} = {
                duration: this.data.track.duration,
                file: { url: this.data.track.url,name: this.data.track.name,size:0,extension:null,native:null,relativePath:null, mime:null, lastModified:null, getData:null},
                id: this.data.track.name,
                type: this.data.track.type,
                process:false
            };
            this.trackList$.next([f]);
        }
        // this.timesHour = Array(23).map((e, i) => i.toString());
        // Object.entries(schulduler_typeList).forEach(([key, value]) => {
        //     this.schulduler_typeList.push({ key, value });
        // })
        // this.usersUnSubcribe = this.users.getAll().subscribe(users => {
        //     this.users$.next(users);
        //     this.loading$.next(false);
        // });
    }
    // ngOnDestroy() {
    //     if (this.usersUnSubcribe) {
    //         this.usersUnSubcribe.unsubscribe();
    //         this.usersUnSubcribe = null;
    //     }
    // }

    public confirm() {
        if (this.loading$.value) {
            return;
        }
        let request;
        const payload = this.getPayload();

        this.loading$.next(true);

        if (this.data.track.id) {
            request = this.plannerSerive.updateTrack(this.data.track.id, payload);
        } else {
            request = this.plannerSerive.createTrack(payload);
        }

        request.pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.close({ state: this.data.track?.id ? 'update' : 'new', data: { track: this.data.track?.id ? { id: this.data.track.id, ...response.plan } : response.plan } });
                const action = this.data.track ? 'updated' : 'created';
                this.toast.open('plannerSerive ' + action);
            }, (errResponse: BackendErrorResponse) => this.errors$.next(errResponse.errors));
    }

    public close(data?: any) {
        this.dialogRef.close(data);
    }

    private getPayload() {
        const payload = {
            ...this.form.value, tracks: (this.trackList$?.value || []).map(e => ({
                name: e.file.name,
                type:e.type,
                duration: e.duration,
                url: e.file.url,
            }))
        };
        return payload;
    }


    public avatarUploadConfig(): UploadApiConfig {
        const uri = this.data.track ?
            `users/${this.data.track.id}/avatar` :
            UploadUri.Image;
        return {
            uri: uri,
            httpParams: { diskPrefix: 'avatars' },
            validator: this.avatarValidator,
        };
    }


    removeTrack(id: string, updateTrackList: boolean = true) {
        const index = this.trackList$.value.findIndex((e: any) => e.id === id);
        if (index > -1) {
            const l = this.trackList$.value;
            l.splice(index, 1);
            if (updateTrackList) {
                this.trackList$.next(l);
            }
            return l;
        }
    }

    public openUploadMusicModal(files: UploadedFile[]) {
        const params = {
            uri: 'uploads',
            validator: this.audioValidator,
            httpParams: { diskPrefix: 'planner_media', disk: 'public' },
            willProcessFiles: true,
        } as UploadApiConfig;
        // openUploadWindow({ types: [UploadInputTypes.audio] }).then(uploadedFiles => {
        // if (!uploadedFiles) return;
        // if this track form is already attached to existing upload queue item
        // replace that item in queue service instead of creating a new item
        // const replacements = uploadedFiles;

        const list = this.data.track.id ? [] : this.trackList$.value || [];

        if (!this.data.track.id) {
            files.forEach((tr: UploadedFile) => {
                const index = this.trackList$.value.findIndex((e: any) => e.id === tr.name);
                if (index > -1) {
                    list.splice(index, 1);
                }
            });
        }

        const map: { id: string, file: UploadedFile, process: boolean, duration: number,type:string }[] = files.map((e: UploadedFile) => {
            return {
                id: e.name,
                file: e,
                type:"",
                process: true,
                duration: 0
            }
        });

        this.trackList$.next([...list, ...map]);
        this.uploadQueue.start(files, params).subscribe(response => {
            if (response.fileEntry) {
                const index = this.trackList$.value.findIndex((e: any) => e.id === response.fileEntry.name);
                const newList = this.trackList$.value;
                if (index > -1) {
                    newList[index].process = false;
                    newList[index].type = (response as TrackUploadResponse)?.metadata?.album_name || this.form.controls['type'].value || "";
                    newList[index].file.url = response?.fileEntry?.url;
                    newList[index].duration = (response as any)?.metadata?.duration;
                    this.trackList$.next(newList);
                }
            }
            // const data = this.overrideData$.value;
            // data.url = response?.fileEntry?.url;
            // data.name = response?.fileEntry?.name;

            this.form.controls['type'].setValue( this.form.controls['type'].value || (response as TrackUploadResponse)?.metadata?.album_name || "");
            // data.duration = (response as any)?.metadata?.duration;
            // this.overrideData$.next(data);
            const queueItem = this.uploadQueue.find(response.queueItemId);
            this.waveGenerator.generate(queueItem.uploadedFile.native).then(waveData => {
                this.form.patchValue({ waveData });
                queueItem.finishProcessing();
            });
            this.toast.open((response?.fileEntry?.name ? response.fileEntry.name : ' Track') + 'uploaded.');
        }, () => this.toast.open('Could not upload track'));


        // });
    }
    deletePlan() {
        this.close({ state: "delete", data: this.data });
    }

    // public get getname() {
    //     return this.form.controls['name'].value;
    // }

    playAudioTrack(audio: string, play: boolean = true) {
        if (!play) {
            this.playAudio = null;
            (this.audioPlayerRef as any).nativeElement.pause();
            return;
        }
        var source = (this.audioPlayerRef as any).nativeElement.querySelector("source");
        if (source) source.src = audio;
        (this.audioPlayerRef as any).nativeElement?.load();
        (this.audioPlayerRef as any).nativeElement?.play();
        this.playAudio = audio;
    }


}
