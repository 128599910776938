<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title *ngIf="data.planner.id">Ändern</h2>
    <h2 class="modal-title" mat-dialog-title *ngIf="!data.planner.id">Erstellen</h2>
    <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form autocomplete="off" (ngSubmit)="confirm()" [formGroup]="form" *ngIf="errors$ | async as errors" ngNativeValidate>
    <mat-dialog-content class="many-inputs">
        <div class="input-container">
            <label for="crupdate-planner-schulduler_type" trans>Active</label>
            <div class="confirmed-select-wrapper">
                <select id="crupdate-planner-schulduler_type" formControlName="active">
                    <option [ngValue]="1">ja</option>
                    <option [ngValue]="0">nein</option>
                </select>
            </div>
            <div class="error" *ngIf="errors?.schulduler_type">{{errors?.type}}</div>
        </div>
        <div>
            <!-- <div class="input-container">
                <label for="crupdate-planner-select" trans>Select User</label>
                <div class="confirmed-select-wrapper">
                    <select id="crupdate-planner" formControlName="user_id" required>
                        <option [ngValue]="u.id" *ngFor="let u of data.users">{{ u.display_name }} | {{ u.email}}
                        </option>
                    </select>
                </div>
            </div> -->

            <div class="input-container">
                <label for="crupdate-planner-title" trans>Title</label>
                <input type="title" id="crupdate-planner-title" formControlName="title" required>
                <div class="error" *ngIf="errors?.title">{{errors?.title}}</div>
            </div>

            <!-- 
            <div class="input-container">
                <label for="crupdate-planner-schulduler_type" trans>Type</label>
                <div class="confirmed-select-wrapper">
                    <select id="crupdate-planner-schulduler_type" formControlName="type">
                        <option [ngValue]="u" trans *ngFor="let u of typeList">{{u}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="errors?.schulduler_type">{{errors?.type}}</div>
            </div> -->

            <div class="input-container">
                <label for="crupdate-planner-schulduler_type" trans>Interval</label>
                <div class="confirmed-select-wrapper">
                    <select id="crupdate-planner-schulduler_type" formControlName="schulduler_type" (change)="schuldulerTypeChanged()">
                        <option [ngValue]="u.key" trans *ngFor="let u of schulduler_typeList">{{u.value}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="errors?.schulduler_type">{{errors?.schulduler_type}}</div>
            </div>


            <div class="input-container" *ngIf="plannerTyp">
                <label for="crupdate-planner-start" trans>Starte am</label>
                <input type="date" id="crupdate-planner-start" formControlName="start" useValueAsDate required>
                <div class="error" *ngIf="errors?.start">{{errors?.start}}</div>
            </div>

            <div class="input-container" *ngIf="plannerTyp && !(plannerTyp=='hourly' || plannerTyp=='minutely')">
                <label for="crupdate-planner-time" trans>Zeit</label>
                <input type="time" id="crupdate-planner-time" formControlName="time" useValueAsDate required>
                <div class="error" *ngIf="errors?.time">{{errors?.time}}</div>
            </div>

            <div class="input-container"  *ngIf="plannerTyp && (plannerTyp=='hourly' ||plannerTyp=='minutely')">
                <label for="crupdate-planner-start-time" trans>Startzeit</label>
            
                <div class="confirmed-start-time-wrapper">
                    <div class="time">00:</div>
                    <div> <input type="number" id="crupdate-planner-time" formControlName="time" required></div>
                </div>
                <div class="error" *ngIf="errors?.time">{{errors?.time}}</div>
            </div>

            <div class="input-container" *ngIf="plannerTyp && plannerTyp=='hourly'">
                <label for="crupdate-planner-data" trans>Widerholen</label>
                <div class="data-select-wrapper">
                    <select id="crupdate-planner-data" formControlName="data" >
                        <option [ngValue]="i+1" trans *ngFor="let u of timesHour;let i=index">{{i+1}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="errors?.data">{{errors?.data}}</div>

            </div>

            <div class="input-container" *ngIf="plannerTyp && plannerTyp=='minutely'">
                <label for="crupdate-planner-data" trans>Widerholen</label>
                <div class="data-select-wrapper">
                    <select id="crupdate-planner-data" formControlName="data">
                        <option [ngValue]="u" trans *ngFor="let u of timesMin;let i=index">{{u}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="errors?.data">{{errors?.data}}</div>

            </div>

            <div class="input-container">
                <label for="crupdate-planner-end" trans>Task läuft bis</label>
                <input type="date" id="crupdate-planner-start" formControlName="end" useValueAsDate required>
                <div class="error" *ngIf="errors?.end">{{errors?.end}}</div>
            </div>

            <div class="tracks">
                <audio #audioPlayerRef>
                    <source [src]="overrideData$.value.audio" type="audio/ogg">
                    Your browser does not support the audio element.
                </audio>
                <h4> Auswahl</h4>
                
                <div class="datatable responsive-table">


                    <table class="material-table" cdkDropList (cdkDropListDropped)="drop($event)">
                        <thead>
                            <tr>
                                <th></th>
                                <th trans>Name</th>
                                <th trans>TYPE</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let track of trackListselected$ | async; let i = index" cdkDrag>
                                <td>
                                    <a class="play-button" mat-icon-button [class.visible]="playAudio!=track.url"
                                        (click)="playAudioTrack(track.url)">
                                        <mat-icon svgIcon="play-arrow"></mat-icon>
                                    </a>

                                    <a class="pause-button" mat-icon-button [class.visible]="playAudio == track.url"
                                        (click)="playAudioTrack(track.url,false)">
                                        <mat-icon svgIcon="pause"></mat-icon>
                                    </a>

                                </td>
                                <td><span>{{track.name}}</span></td>
                                <td><span>{{track.type}}</span></td>
                              
                                <td>
                                    <a (click)="remove(i)" mat-button class="button">
                                        <mat-icon svgIcon="delete"></mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h4> Tracks</h4>
                <div class="datatable responsive-table">
                    <div class="input-container search-input-container">
                        <mat-icon class="search-icon" svgIcon="search"></mat-icon>
                        <input
                            type="text"
                            class="search-input"
                            placeholder="Suchen..."
                            (keyup)="search($event)"
                        />
                      </div>

                    <table class="material-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th table-sort-header trans (click)="sort('name')">title</th>
                                <th table-sort-header trans (click)="sort('type')">type</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let track of trackLists$ | async">
                                <td>
                                    <a class="play-button" mat-icon-button [class.visible]="playAudio!=track.url"
                                        (click)="playAudioTrack(track.url)">
                                        <mat-icon svgIcon="play-arrow"></mat-icon>
                                    </a>

                                    <a class="pause-button" mat-icon-button [class.visible]="playAudio == track.url"
                                        (click)="playAudioTrack(track.url,false)">
                                        <mat-icon svgIcon="pause"></mat-icon>
                                    </a>

                                </td>
                                <td><span>{{track.name}}</span></td>
                                <td><span>{{track.type}}</span></td>
                                <td>
                                    <a (click)="addList(track)"  mat-button class="button">
                                        <mat-icon svgIcon="add"></mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>


    </mat-dialog-content>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="deletePlan()" type="button" mat-button class="button delete" trans
        *ngIf="data.planner.id">Löschen</button>
        <button (click)="close()" type="button" mat-button class="button cancel" trans>Cancel</button>
        <button type="submit" class="button primary" mat-raised-button color="accent"
            [disabled]="!getUser ||!plannerTyp || !getStart || trackListselected$.value.length<1">
            <span *ngIf="data.planner.id" trans>Speichern</span>
            <span *ngIf="!data.planner.id" trans>Create</span>
        </button>
    </div>
</form>