<div class="header" *ngIf="!multipleTracksSelected()">
    <media-image [media]="data.item"></media-image>
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.track(data.item)">{{data.item.name}}</a>
        <artists-links-list class="secondary-name" [artists]="data.item.artists"></artists-links-list>
    </div>
</div>

<div class="context-menu-panel primary-panel" [class.hidden]="!activePanelIs('primary')">
    <div class="panel-body">
        <ng-content></ng-content>
        <div class="context-menu-item" (click)="goToTrackRadio()"  *ngIf="!multipleTracksSelected() && !settings.get('player.hide_radio_button')" trans>Go to Track Radio</div>
        <div class="context-menu-item" (click)="addToQueue()" *ngIf="!inQueue() || multipleTracksSelected()" trans>Add to Queue</div>
        <div class="context-menu-item" (click)="removeFromQueue()" *ngIf="inQueue() && !multipleTracksSelected()" trans>Remove from Queue</div>
        <div class="context-menu-item" (click)="removeFromPlaylist()" *ngIf="data.playlist.collaborative || data.playlist.owner_id === currentUser.get('id')" trans>Remove from Playlist</div>
        <div class="context-menu-item" (click)="saveToLibrary()" *ngIf="!inLibrary() || multipleTracksSelected()" trans>Save to Your Music</div>
        <div class="context-menu-item" (click)="removeFromLibrary()" *ngIf="inLibrary() && !multipleTracksSelected()" trans>Remove from Your Music</div>
        <div class="context-menu-item" (click)="showLyricsModal()"  *ngIf="!multipleTracksSelected()" trans>Show Lyrics</div>
        <div class="context-menu-item" (click)="copyLinkToClipboard()" *ngIf="!multipleTracksSelected()" trans>Copy Track Link</div>
        <div class="context-menu-item" (click)="openShareModal()" *ngIf="!multipleTracksSelected()" trans>Share</div>
        <div class="context-menu-item" (click)="downloadTrack()" *ngIf="!multipleTracksSelected() && settings.get('player.enable_download') && data.item.url" trans>Download</div>
        <a class="context-menu-item"  routerLink="admin/tracks" [queryParams]="{track_id: data.item.id}" *ngIf="currentUser.hasPermission('tracks.update')" trans>Edit</a>
    </div>
</div>

