<div class="comment-list-header" *ngIf="(trackComments.pagination$ | async)?.data?.length">
    <mat-icon svgIcon="comment"></mat-icon>
    {{ trackComments.mediaItem.comments_count }} {{ 'comments' | trans }}
</div>
<div class="comment-list">
    <ng-container *ngFor="let comment of (trackComments.pagination$ | async)?.data">
        <comment-list-item [comment]="comment"></comment-list-item>
    </ng-container>
</div>

<loading-indicator [isVisible]="trackComments.loadingMore$ | async" class="inline"></loading-indicator>

<div class="no-results-message" *ngIf="!(trackComments.pagination$ | async)?.data?.length">
    <mat-icon class="icon" svgIcon="comment"></mat-icon>
    <div class="text">
        <div class="main" trans>Seems a little quiet over here
        </div>
        <div class="secondary" trans>Be the first to comment on this track</div>
    </div>
</div>
