import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { User } from '@common/core/types/models/User';
import { Users } from '@common/auth/users.service';
import { Toast } from '@common/core/ui/toast.service';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { UploadApiConfig } from '@common/uploads/types/upload-api-config';
import { AvatarValidator } from '@common/account-settings/avatar-validator';
import { UploadUri } from '@common/uploads/types/upload-uri.enum';
import { randomString } from '@common/core/utils/random-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { AudioUploadValidator } from 'src/app/web-player/audio-upload-validator';
import { openUploadWindow } from '@common/uploads/utils/open-upload-window';
import { UploadInputTypes } from '@common/uploads/upload-input-config';
import { UploadQueueService } from '@common/uploads/upload-queue/upload-queue.service';
import { WaveformGenerator } from 'src/app/web-player/tracks/waveform/waveform-generator';
import { DefaultImagePaths } from 'src/app/web-player/default-image-paths.enum';
import { PlannerService } from 'src/app/web-player/tracks/planner.service';
import { Planner, PlannerTrack } from 'src/app/models/Planner';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { schulduler_typeList } from '../schulduler-type-list';

export interface CrupUserPlanModalData {
    planner?: Planner;
    state:"",
    trackList:PlannerTrack[]
}


@Component({
    selector: 'crup-user-plan-modal',
    templateUrl: './crup-user-plan-modal.html',
    styleUrls: ['./crup-user-plan-modal.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class CrupUserPlanModalComponent implements OnInit {
    public loading$ = new BehaviorSubject<boolean>(false);
    @ViewChild('audioPlayerRef') protected audioPlayerRef: ElementRef;
    playAudio: string;
    // users$ = new BehaviorSubject<User[]>([]);
    public user: String;
    public overrideData$= new BehaviorSubject<{name?:string,audio?:string,duration?:number}>({name:null,audio:null,duration:null});
    public form = this.fb.group({
        title: [''],
        user_id: [''],
        type: [''],
        schulduler_type:[''],
        start: [''],
        end: [''],
        data:[''],
        time: [''],
        active: [1]
    });
    public errors$ = new BehaviorSubject<Partial<Planner>>({});
    
    public trackListselected$= new BehaviorSubject<PlannerTrack[]>([]);
    public trackLists$= new BehaviorSubject<PlannerTrack[]>([]);

    public schulduler_typeList =[];
    public typeList: string[] = ["Audiologo","Advertisement","Announcement"];
    
    public timesHour =[];
    public timesMin = [2,10,15,20,25,30,45];
    public timeStartMin = ["00:00","00:15","00:30","00:45"];
    //  ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"];
    usersUnSubcribe: Subscription;
    public defaultImage$ = new BehaviorSubject<string>(DefaultImagePaths.track);
    planSortUp: any;
    trackQuery:string ="";
    constructor(
        private dialogRef: MatDialogRef<CrupUserPlanModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CrupUserPlanModalData,
        private users: Users,
        private plannerSerive: PlannerService,
        private toast: Toast,
        private fb: FormBuilder,
        private avatarValidator: AvatarValidator,
        private audioValidator: AudioUploadValidator,
        private uploadQueue: UploadQueueService,
        private waveGenerator: WaveformGenerator,
    ) { }

    public get getStart() {
        return this.form.controls['start'].value;
    }
    public get plannerTyp() {
        return this.form.controls['schulduler_type'].value;
    }
    public get isActive() {
        return this.form.controls['active'].value;
    }
    public get getUser() {
        return this.form.controls['user_id'].value;
    }
    getDate(v: any, s: number, e: number) {
        try {
            const d = new Date(v);
            d.setUTCHours(d.getHours());
            return d.toISOString().substring(s, e);
        } catch (error) {
            return null
        }

    }
    search(event:any){
        this.trackLists$.next(this.data.trackList?.filter((e:any)=>e.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) )||[]);
    }
    drop(e :CdkDragDrop<PlannerTrack>){
        const newData = [...this.trackListselected$.value];
        moveItemInArray(newData, e.previousIndex, e.currentIndex);
        this.trackListselected$.next(newData);
    }

    schuldulerTypeChanged(){
        this.form.controls["time"].setValue("0");
    }
    
    sort(key){
        this.planSortUp  = !this.planSortUp;
        this.data.trackList = this.data.trackList.sort((a:any, b:any)=>{
          if(a[key]>b[key]) return this.planSortUp ? 1 : -1;
          if(a[key]<b[key]) return this.planSortUp ? -1 : 1;
              return 0;
        });
    }

    ngOnInit() {
        
        this.trackLists$.next(this.data.trackList||[]);
        if (this.data.planner) {
            this.data.planner.tracks = this.data.planner.tracks || [];
            this.trackListselected$.next(this.data.planner.tracks);
            // password input should always be empty
            if((this.data.planner.schulduler_type=="hourly" || this.data.planner.schulduler_type=="minutely")){
                try {
                    // this.data.planner.data = parseInt(this.data.planner.time.toString());
                    this.data.planner.data = parseInt(this.data.planner.data.toString());
                } catch (error) {
                    
                }
            }
            console.log(this.data.planner);
            this.form.patchValue({
                ...this.data.planner,
                start: this.getDate(this.data.planner.start?.replace(" 00:00:00","T00:00:00.000Z"), 0, 10),
                end: this.getDate(this.data.planner.end, 0, 10),
            });
            // this.overrideData$.next({...this.data.planner});
        }
        this.timesHour =  Array(23).map((e,i)=>i.toString());
        // this.timesMin =  [5,10,15,30];
        Object.entries(schulduler_typeList).forEach(([key,value])=>{
            this.schulduler_typeList.push({key,value});
        })
        // this.usersUnSubcribe = this.users.getAll().subscribe(users => {
        //     this.users$.next(users);
        //     this.loading$.next(false);
        // });
    }
    addList(track:PlannerTrack){
        this.trackListselected$.next([...this.trackListselected$.value,track]);
    }
    remove(i:number){
        const l = this.trackListselected$.value;
        l.splice(i,1);
        this.trackListselected$.next([...l]);
    }

    ngOnDestroy() {
        if (this.usersUnSubcribe) {
            this.usersUnSubcribe.unsubscribe();
            this.usersUnSubcribe = null;
        }
    }

    public confirm() {
        if (this.loading$.value) {
            return;
        }
        let request;
        const payload = this.getPayload();

        this.loading$.next(true);

        if (this.data.planner.id) {
            request = this.plannerSerive.update(this.data.planner.id, payload);
        } else {
            request = this.plannerSerive.create(payload);
        }

        request.pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.close({ state: this.data.planner?.id ? 'update' : 'new', data: {planner:this.data.planner?.id ? { id: this.data.planner.id, ...response.plan } : response.plan} });
                const action = this.data.planner ? 'updated' : 'created';
                this.toast.open('plannerSerive ' + action);
            }, (errResponse: BackendErrorResponse) => this.errors$.next(errResponse.errors));
    }

    public close(data?: any) {
        this.dialogRef.close(data);
    }

    private getPayload() {
        const start =   new Date(this.form.controls['start'].value);
        // start.setHours(23, 59, 0, 0);
        const end =   new Date(this.form.controls['end'].value);
        end.setHours(23, 59, 0, 0);
        const payload = {...this.form.value,start,end,tracks: this.trackListselected$.value.map(e=>e.id)};
        return payload;
    }


    public avatarUploadConfig(): UploadApiConfig {
        const uri = this.data.planner ?
            `users/${this.data.planner.id}/avatar` :
            UploadUri.Image;
        return {
            uri: uri,
            httpParams: { diskPrefix: 'avatars' },
            validator: this.avatarValidator,
        };
    }



    // public openUploadMusicModal() {
    //     this.overrideData$.next({... this.overrideData$.value,audio:null,name:null});
    //     const params = {
    //         uri: 'uploads',
    //         validator: this.audioValidator,
    //         httpParams: { diskPrefix: 'planner_media', disk: 'public' },
    //         willProcessFiles: true,
    //     } as UploadApiConfig;
    //     openUploadWindow({ types: [UploadInputTypes.audio] }).then(uploadedFiles => {
    //         if (!uploadedFiles) return;
    //         // if this track form is already attached to existing upload queue item
    //         // replace that item in queue service instead of creating a new item
    //         const replacements = uploadedFiles;
    //         this.uploadQueue.start(replacements, params).subscribe(response => {
    //             const data = this.overrideData$.value;
    //             data.audio = response?.fileEntry?.url;
    //             data.name = response?.fileEntry?.name;
    //             data.duration = (response as any)?.metadata?.duration;
    //             this.overrideData$.next(data);
    //             const queueItem = this.uploadQueue.find(response.queueItemId);
    //             this.waveGenerator.generate(queueItem.uploadedFile.native).then(waveData => {
    //                 this.form.patchValue({ waveData });
    //                 queueItem.finishProcessing();
    //             });
    //             this.toast.open('Track uploaded.');
    //         }, () => this.toast.open('Could not upload track'));

    //     });
    // }
    deletePlan() {
        this.close({ state: "delete", data: this.data });
    }

    
  playAudioTrack(audio:string,play:boolean = true){
    if(!play){
      this.playAudio = null;
      ( this.audioPlayerRef as any).nativeElement.pause();
      return;
    }
    var source = ( this.audioPlayerRef as any).nativeElement.querySelector("source");
    if(source)source.src = audio;
    ( this.audioPlayerRef as any).nativeElement?.load(); 
    ( this.audioPlayerRef as any).nativeElement?.play(); 
    this.playAudio = audio;
  }


}
