import {ChangeDetectionStrategy, Component,Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CurrentUser} from '@common/auth/current-user';
import {Settings} from '@common/core/config/settings.service';


export interface UserRequireModalData {
    text: string;
}

@Component({
    selector: 'user-require-dialog.component',
    templateUrl: './user-require-dialog.component.html',
    styleUrls: ['./user-require-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRequireDialogComponent {
    constructor(
        public settings: Settings,
        private dialogRef: MatDialogRef<UserRequireDialogComponent>,
        public currentUser: CurrentUser,
        @Inject(MAT_DIALOG_DATA) public data: UserRequireModalData,
    ) {
    }
    public close() {
        this.dialogRef.close();
    }
}
