<ng-container *ngIf="pagination$ | async as pagination">
    <no-results-message *ngIf="!hideNoResultsMessage && !pagination.data.length" svgIcon="audiotrack">
        <span primary-text trans>No tracks yet</span>
        <span secondary-text><ng-content></ng-content></span>
    </no-results-message>
    <div>
        <media-list-item [media]="track" [reposter]="reposter" *ngFor="let track of pagination.data" [tracks]="pagination.data"></media-list-item>
    </div>
</ng-container>
<loading-indicator [isVisible]="loading$ | async" class="inline"></loading-indicator>
