<div class="modal-header">
    <div class="dialog-title-image">
        <img class="album-border-image" [src]="settings.getAssetUrl('images/audiorooms-logo.png')" alt="album border image">

    </div>
    <ng-template #elseBlock><h3 mat-dialog-title trans>Only for registered users </h3></ng-template>
    <button
        type="button"
        mat-dialog-close
        class="close-button"
        mat-icon-button
        tabindex="-1"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content >
    <ng-container>
        <ng-container>
            <div class="content">
                <h2 class="content-title" trans *ngIf="data.text;else elseBlock">{{data.text}} </h2>
                <div class="buttons">
                    <a mat-dialog-close  routerLink="/login" mat-raised-button color="accent" trans>Login</a>
                </div>
                <div class="text"><span trans>Noch keinen Account? </span> <a href="https://audiorooms.de/registrierung/" class="primary-color" target="_blank" trans>Registrierung</a></div>
            </div>
        </ng-container>

    </ng-container>
</mat-dialog-content>

