import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Translations} from '../../translations/translations.service';
import {Settings} from '../../config/settings.service';
import { User } from '@common/core/types/models/User';
import { Users } from '@common/auth/users.service';
import { BehaviorSubject, Observable } from 'rxjs';

export interface DuplicateConfirmModalData {
    title: string;
    body: string;
    userid?:string,
    bodyBold?: string;
    ok?: string;
    cancel?: string;
    replacements?: object;
}

@Component({
    selector: 'duplicate-playlist-confirm-modal',
    templateUrl: './duplicate-playlist-confirm-modal.component.html',
    styleUrls: ['./duplicate-playlist-confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateConfirmModalComponent implements OnInit {
    user:String;
    users$ = new BehaviorSubject<User[]>([]);
    loading$ = new BehaviorSubject(true);


    constructor(
        private dialogRef: MatDialogRef<DuplicateConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DuplicateConfirmModalData,
        private i18n: Translations,
        public settings: Settings,
        private users: Users

    ) {
        this.user = data.userid || '';
    }

    ngOnInit() {
        if ( ! this.data.ok) {
            this.data.ok = 'Confirm';
        }
        if ( ! this.data.cancel) {
            this.data.cancel = 'Cancel';
        }
        for (const key in this.data) {
            if (typeof this.data[key] === 'string') {
                this.data[key] = this.i18n.t(this.data[key], this.data.replacements);
            }
        }
        this.loading$.next(true);
        this.users.getAll().subscribe(users => {
            this.users$.next(users);
            this.loading$.next(false);
        });
    }

    public confirm() {
        this.dialogRef.close({user:this.user});
    }

    public close() {
        this.dialogRef.close(false);
    }
}
