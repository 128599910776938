import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Playlist} from '../../../../models/Playlist';
import {Settings} from '@common/core/config/settings.service';
import {WebPlayerState} from '../../../web-player-state.service';
import {InfiniteScroll} from '@common/core/ui/infinite-scroll/infinite.scroll';
import {CurrentUser} from '@common/auth/current-user';
import {DatatableService} from '@common/datatable/datatable.service';
import {map} from 'rxjs/operators';
import { CrupdatePlaylistModalComponent } from 'src/app/web-player/playlists/crupdate-playlist-modal/crupdate-playlist-modal.component';
import { Modal } from '@common/core/ui/dialogs/modal.service';
import { Router } from '@angular/router';
import { UserPlaylists } from 'src/app/web-player/playlists/user-playlists.service';
import { Playlists } from 'src/app/web-player/playlists/playlists.service';

@Component({
    selector: 'library-playlists',
    templateUrl: './library-playlists.component.html',
    styleUrls: ['./library-playlists.component.scss'],
    host: {class: 'user-library-page'},
    providers: [DatatableService],
})
export class LibraryPlaylistsComponent extends InfiniteScroll implements OnInit, OnDestroy {
    public totalPlaylists$ = this.datatable.paginator.response$.pipe(map(r =>  r?.pagination?.total || 0))
    private playlistApiObs$:any;
    constructor(
        private settings: Settings,
        private state: WebPlayerState,
        protected zone: NgZone,
        private currentUser: CurrentUser,
        public datatable: DatatableService<Playlist>,
        private router: Router,
        public userPlaylists: UserPlaylists,
        private playlistsApi: Playlists,
        private modal: Modal
    ) {
        super();
        this.datatable.paginator.dontUpdateQueryParams = true;
    }

    ngOnInit() {
        this.loadPlaylist();
        this.el = this.state.scrollContainer;
        super.ngOnInit();
        this.playlistApiObs$= this.playlistsApi.deleted$.subscribe(e=>{
            this.loadPlaylist();
        });
    }

    loadPlaylist(){
        this.datatable.init({
            uri: `users/${this.currentUser.get('id')}/playlists`,
            infiniteScroll: true,
        });
    }

    public openNewPlaylistModal() {
        if ( ! this.currentUser.isLoggedIn()) {
            return this.router.navigate(['/login']);
        }

        this.modal.open(CrupdatePlaylistModalComponent)
            .afterClosed().subscribe(playlist => {
                if (playlist) {
                    this.userPlaylists.add(playlist);
                }
            });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.playlistApiObs$.unsubscribe();
        this.datatable.destroy();
    }

    public canLoadMore() {
        return this.datatable.paginator.canLoadNextPage();
    }

    protected isLoading() {
        return this.datatable.paginator.loading$.value;
    }

    protected loadMoreItems() {
        this.datatable.paginator.nextPage();
    }

    public trackByFn = (i: number, playlist: Playlist) => playlist.id;
}
