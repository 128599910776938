import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import {BehaviorSubject, EMPTY, of} from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ChannelService } from 'src/app/admin/channels/channel.service';
import {Channel} from '../../../admin/channels/channel';
import { Albums } from '../../albums/albums.service';
import { Playlists } from '../../playlists/playlists.service';
import {WebPlayerState} from '../../web-player-state.service';

@Component({
    selector: 'channel-show',
    templateUrl: './channel-show.component.html',
    styleUrls: ['./channel-show.component.scss'],
})
export class ChannelShowComponent implements OnInit {
    @Input() set channel(channel: Channel) {
        this.channel$.next(channel);
    }
    private albumsObs$:any;
    private playlistApiObs$:any;

    public channel$ = new BehaviorSubject<Channel>(null);
    public loading$ = new BehaviorSubject(false);

    constructor(private route: ActivatedRoute, private state: WebPlayerState,
        private playlistsApi: Playlists,
        private albumApi:Albums,
        private channels: ChannelService,

        
        ) {}

    ngOnInit() {
        if (this.state.scrollContainer) {
            this.state.scrollContainer.nativeElement.scrollTop = 0;
        }
        this.albumsObs$= this.albumApi.albumsDeleted$.subscribe(e=>{
            this.loadChanelData();
        });
        this.playlistApiObs$= this.playlistsApi.deleted$.subscribe(e=>{
            this.loadChanelData();
        });

        this.route.data.subscribe(data => {
            if (data.api && data.api.channel) {
                this.channel$.next(data.api.channel);
            }
        });
    }

    loadChanelData(){
    
        const idOrSlug = +this.route.snapshot.params.id || this.route.snapshot.params.slug || this.route.snapshot.data.id || this.route.snapshot.data.channelName;
        if ( ! idOrSlug) {
            return null;
        }
        this.loading$.next(true);
        return this.channels.get(idOrSlug, {filter: this.route.snapshot.params.filter, forAdmin: this.route.snapshot.data.forAdmin}, {suppressAuthToast: true}).pipe(
            catchError((e: BackendErrorResponse) => {
                return EMPTY;
            }),
            mergeMap(response => {
                if (response) {
                    return of(response);
                } else {
                    return EMPTY;
                }
            })
        ).subscribe((data:any) => {
        this.loading$.next(false);

            if (data.channel) {
                this.channel$.next(data.channel);
            }
        });
    }

    ngOnDestroy() {
        this.albumsObs$.unsubscribe();
        this.playlistApiObs$.unsubscribe();

    }
}
