import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';

@Component({
  selector: 'plan-required',
  templateUrl: './plan-required.component.html',
  styleUrls: ['./plan-required.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanRequiredComponent implements OnInit {

  constructor(public settings: Settings ) { }

  ngOnInit() {
  }

}
