<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title *ngIf="data.track" trans>Track ändern</h2>
    <h2 class="modal-title" mat-dialog-title *ngIf="!data.track" trans>Track Hochladen</h2>
    <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<audio #audioPlayerRef hidden>
    <source [src]="playAudio" type="audio/ogg">
    Your browser does not support the audio element.
  </audio>
<form autocomplete="off" (ngSubmit)="confirm()" [formGroup]="form" *ngIf="errors$ | async as errors" ngNativeValidate>
    <mat-dialog-content class="many-inputs">
        <div>
            <div class="upload-container">
                <div class="dropzone" [fileDropzone]="uploadConfig" [clickButton]="clickButton"
                    (filesDropped)="openUploadMusicModal($event)">
                    <button mat-flat-button c olor="accent" type="button" class="left-column-action-button"
                        #clickMatButton>
                        <span trans>Upload Track</span>
                    </button>
                </div>
            </div>
            <div>
                <table class="material-table">
                    <!-- <thead>
                        <tr>
                            <th></th>
                            <th trans>title</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead> -->
                    <tbody>
                        <tr *ngFor="let track of trackList$ | async">
                            <!-- <td>
                                <a class="play-button" mat-icon-button [class.visible]="playAudio!=track.url"
                                    (click)="playAudioTrack(track.url)">
                                    <mat-icon svgIcon="play-arrow"></mat-icon>
                                </a>

                                <a class="pause-button" mat-icon-button [class.visible]="playAudio == track.url"
                                    (click)="playAudioTrack(track.url,false)">
                                    <mat-icon svgIcon="pause"></mat-icon>
                                </a>
                            </td> -->

                            <td><span>{{track.file.name}}</span></td>
                            <td>
                                {{track.process ? "warten...":""}}
                            </td>
                            <td>
                                <a mat-icon-button (click)="removeTrack(track.file.name)"
                                    [matTooltip]="'delete' | trans">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="input-container" *ngIf="data.track.id">
                <label for="crupdate-planner-title" trans>Title</label>
                <input type="text" id="crupdate-planner-title" formControlName="name" required>
                <div class="error" *ngIf="errors?.title">{{errors?.name}}</div>
            </div> -->

            <div class="input-container">
                <label for="crupdate-planner-schulduler_type" trans>Type</label>
                <input type="text" id="crupdate-planner-title" formControlName="type" required>
                <div class="error" *ngIf="errors?.title">{{errors?.type}}</div>
            </div>
            <!-- <div class="input-container">
                <label for="crupdate-planner-schulduler_type" trans>Type</label>
                <div class="confirmed-select-wrapper">
                    <select id="crupdate-planner-schulduler_type" formControlName="type">
                        <option [ngValue]="u" trans *ngFor="let u of typeList">{{u}}</option>
                    </select>
                </div>
                <div class="error" *ngIf="errors?.schulduler_type">{{errors?.type}}</div>
            </div> -->
        </div>
    </mat-dialog-content>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="deletePlan()" type="button" mat-button class="button delete" trans
            *ngIf="data.track.id">Löschen</button>
        <button (click)="close()" type="button" mat-button class="button cancel" trans>Cancel</button>
        <button type="submit" class="button primary" mat-raised-button color="accent">
            <span *ngIf="data.track.id" trans>Speichern</span>
            <span *ngIf="!data.track.id" trans>Create</span>
        </button>
    </div>
</form>