<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Edit your profile</h2>
    <button type="button" mat-icon-button (click)="close()" tabindex="-1" class="close-button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form (ngSubmit)="confirm()" [formGroup]="form" ngNativeValidate>
    <mat-dialog-content>
        <ng-container formGroupName="profile">
            <upload-image-control class="header-image-control" buttonText="Upload header image" [uploadConfig]="headerImageUploadConfig()" (fileDeleted)="confirm(false)" formControlName="header_image"></upload-image-control>
        </ng-container>
        <div class="flex-container">
            <ng-container formGroupName="user">
                <upload-image-control [uploadConfig]="avatarUploadConfig()" buttonText="Upload avatar" formControlName="avatar"></upload-image-control>
            </ng-container>
            <div class="many-inputs right-column">
                <ng-container formGroupName="user">
                    <div class="input-container">
                        <label for="profile-username" trans>Username</label>
                        <input type="text" id="profile-username" formControlName="username" required>
                    </div>

                    <div class="inline-inputs-container many-inputs">
                        <div class="input-container">
                            <label for="profile-first_name" trans>First Name</label>
                            <input type="text" id="profile-first_name" formControlName="first_name">
                        </div>
                        <div class="input-container">
                            <label for="profile-last_name" trans>Last Name</label>
                            <input type="text" id="profile-last_name" formControlName="last_name">
                        </div>
                    </div>
                </ng-container>

                <ng-container formGroupName="profile">
                    <div class="inline-inputs-container many-inputs">
                        <div class="input-container">
                            <label for="profile-city" trans>City</label>
                            <input type="text" id="profile-city" formControlName="city">
                        </div>
                        <div class="input-container">
                            <label for="profile-country" trans>Country</label>
                            <select id="profile-country" formControlName="country">
                                <option [value]="country.name" *ngFor="let country of countries$ | async">{{country.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="input-container">
                        <label for="profile-description" trans>Description</label>
                        <textarea type="text" id="profile-description" formControlName="description" rows="6" maxlength="250"></textarea>
                    </div>
                </ng-container>
            </div>
        </div>
        <profile-links-form-control formControlName="links"></profile-links-form-control>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Close</button>
        <button type="submit" mat-raised-button color="accent" class="submit-button" [disabled]="loading$ | async" trans>Save</button>
    </mat-dialog-actions>
</form>
